import useApi from '../hooks/api.hook'
import { useSelector } from 'react-redux'
import * as filterSelectors from '../redux/selectors/filter.selectors'

export default function useAdminApi() {
    const { publicRequest, protectedRequest } = useApi()

    const filterInvoice = useSelector(filterSelectors.invoice)
    const filterPayment = useSelector(filterSelectors.payment)
    const filterUnknow = useSelector(filterSelectors.unknow)
    const filterWithdraw = useSelector(filterSelectors.withdraw)


    const twoFA = async (login, password) => {
        try { return await publicRequest('api/moderator/2fa', {login, password}) }
        catch(error) { return null } 
    }

    const verify = async (login, password, code) => {
        try { return await publicRequest('api/moderator/login', {login, password, code}) }
        catch(error) { return null } 
    }

    const confirmEmergent = async (id) => {
        try { return await protectedRequest('api/moderator/confirm-emergent', {id}) }
        catch(error) { return null } 
    }

    const rejectEmergent = async (id) => {
        try { return await protectedRequest('api/moderator/reject-emergent', {id}) }
        catch(error) { return null } 
    }

    const invoiceReport = async (start, stop, update=false, isClient=false, filter=null) => {
        filter = filter? filter : filterInvoice

        try { return await protectedRequest('api/moderator/invoice-report', {start, stop, update, isClient, filter}) }
        catch(error) { return null } 
    }

    const paymentReport = async (start, stop, update=false, filter=null) => {
        filter = filter? filter : filterPayment
        
        try { return await protectedRequest('api/moderator/payment-report', {start, stop, update, filter}) }
        catch(error) { return null } 
    }

    const unknowReport = async (start, stop, update=false) => {
        try { return await protectedRequest('api/moderator/unknow-report', {start, stop, update, filter: filterUnknow }) }
        catch(error) { return null } 
    }

    const withdrawReport = async (start, stop, update=false) => {
        try { return await protectedRequest('api/moderator/withdraw-report', {start, stop, update, filter: filterWithdraw }) }
        catch(error) { return null } 
    }

    const workerStatistic = async (id, start, stop) => {
        try { return await protectedRequest('api/moderator/worker-statistic', {id, start, stop}) }
        catch(error) { return null } 
    }

    const partnerStatistic = async (id, start, stop) => {
        try { return await protectedRequest('api/moderator/partner-statistic', {id, start, stop}) }
        catch(error) { return null } 
    }

    const statistic = async (start, stop) => {
        try { return await protectedRequest('api/moderator/statistic', {start, stop}) }
        catch(error) { return null } 
    }

    const forseConfirm = async (id, amount) => {
        try { return await protectedRequest('api/moderator/forse-invoice', {id, amount, status: 'CONFIRM'}) }
        catch(error) { return null } 
    }

    const forseReject = async (id) => {
        try { return await protectedRequest('api/moderator/forse-invoice', {id, status: 'REJECT'}) }
        catch(error) { return null } 
    }

    const toggleWorkerOut = async (worker) => {
        try { return await protectedRequest('api/moderator/toggle-partner-out', {worker}) }
        catch(error) { return [] } 
    }

    const toggleWorkerIn = async (worker) => {
        try { return await protectedRequest('api/moderator/toggle-partner-in', {worker}) }
        catch(error) { return [] } 
    }

    const toggleWorkerIban = async (worker) => {
        try { return await protectedRequest('api/moderator/toggle-worker-iban', {worker}) }
        catch(error) { return [] } 
    }

    const swap = async (from, to, amount, course, tx) => {
        try { return await protectedRequest('api/moderator/swap', {from, to, amount, course, tx}) }
        catch(error) { return false } 
    }

    const withdraw = async (from, to, amount, course, tx) => {
        try { return await protectedRequest('api/moderator/withdraw', {from, to, amount, course, tx}) }
        catch(error) { return false } 
    }

    const createPayment = async (amount, card) => {
        try { return await protectedRequest('api/moderator/create-payment', {amount, card}) }
        catch(error) { return false } 
    }

    const forsePayment = async (id, status) => {
        try { return await protectedRequest('api/moderator/forse-payment', {id, status}) }
        catch(error) { return false } 
    }

    const cardsConversion = async () => {
        try { return await protectedRequest('api/moderator/cards-conversion') }
        catch(error) { return null } 
    }

    const getWorkersWaitBalance = async () => {
        try { return await protectedRequest('api/moderator/getWorkersWaitBalance') }
        catch(error) { return {} } 
    }

    const getPartnersWaitBalance = async () => {
        try { return await protectedRequest('api/moderator/getPartnersWaitBalance') }
        catch(error) { return {} } 
    }

    // ===

    const changePassword = async (password) => {
        try { return await protectedRequest('api/worker/change-password', {password}) }
        catch(error) { return [] } 
    }

    const changeTelegram = async (telegram) => {
        try { return await protectedRequest('api/worker/change-telegram', {telegram}) }
        catch(error) { return [] } 
    }
    
    const changeGroup = async (group) => {
        try { return await protectedRequest('api/worker/change-group', {group}) }
        catch(error) { return [] } 
    }

    const getBalance = async () => {
        try { return await protectedRequest('api/worker/balance') }
        catch(error) { return 0 } 
    }

    return { 
        twoFA,
        verify,
        
        invoiceReport,
        paymentReport,
        unknowReport,
        withdrawReport,

        workerStatistic,
        partnerStatistic,
        statistic,

        toggleWorkerOut,
        toggleWorkerIn,

        changePassword,
        changeTelegram,
        changeGroup,
        getBalance,

        forseConfirm,
        forseReject,

        confirmEmergent,
        rejectEmergent,

        swap,
        withdraw,
        createPayment,
        forsePayment,

        cardsConversion,
        toggleWorkerIban,
        getWorkersWaitBalance,
        getPartnersWaitBalance
    }
}