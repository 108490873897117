import React from 'react'
import { useSelector } from 'react-redux'
import { formatTime } from '../../utils/time.utils'
import useAdminApi from '../../API/admin.api'
import * as authSelectors from '../../redux/selectors/auth.selectors'

import styles from '../../styles/components/admin/payment.module.css'


function Payment({payment, refresh=()=>{}}) {
    const access = useSelector(authSelectors.adminAccess)
    const forseAccess = (access === 'ADMIN')

    const adminApi = useAdminApi()

    const forseHandler = async () => {
        const status = payment.status === 'CONFIRM'? 'REJECT' : 'CONFIRM'
        const res = await adminApi.forsePayment(payment._id, status)
        if(res) { refresh() }
    }

    return (
        <div className={styles.main}>
            <div>{payment?.amount} ₴</div>
            <div className={styles.iban}>
                <div>{payment?.card}</div>
                <div>{payment?.iban?.number}</div>
                <div>{payment?.iban?.INN}</div>
                <div>{payment?.iban?.number && (payment?.iban?.target || 'dafault')}</div>
            </div>
            
            <div>{payment?.workerName || payment?.worker}</div>
            <div>{payment?.partnerName || payment?.partner}</div>
            <div>{payment?._id}</div>
            <div className={`${styles.status} ${payment?.status}`}>{payment?.status}</div>
            <div>
                {forseAccess && <button className={styles.button} onClick={() => forseHandler()}>forse</button>}
            </div>
            <div className={styles.date}>
                {formatTime(payment?.createdAt).replaceAll(',', " ")}
            </div>
        </div>
      )
}

export default Payment